import React, { useState, useEffect } from 'react'
import { jwtDecode } from "jwt-decode"
import { Link } from "react-router-dom"
import "./ListaEmpresas.scss"

function ListaEmpresasPA() {
    const [loadingEmpresas, setLoadingEmpresas] = useState(false)
    const [empresas, setEmpresas] = useState([])

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const fetchEmpresas = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/empresasView`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER,
                })
            })
            const data = await res.json()
            if(data.status !== 0){
                setLoadingEmpresas(false)
            } else {
                setLoadingEmpresas(false)
                setEmpresas(data.objeto)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        fetchEmpresas()
    },[])

    return (
        <>
            {loadingEmpresas ? (
                <p>Loading...</p>
            ) : (
                <>
                {empresas.length > 0 ? (
                    <div className='table__custom__emp'>
                    <div className='table__custom__emp__header'>
                        <div className='table__custom__emp__cell table__custom__emp__cell--title fw-bold cell__id text-center'></div>
                        <div className='table__custom__emp__cell table__custom__emp__cell--title fw-bold cell__name'>Nombre</div>
                        <div className='table__custom__emp__cell table__custom__emp__cell--title fw-bold'>Responsable</div>
                    </div>
                    <div className='table__custom__emp__body'>
                        {empresas.map((e, i) => {
                        return <Link key={i} className='table__custom__emp__row text-decoration-none text-dark' to={`/planes-de-accion/consultora/${USER.idConsultora}/empresa/${e.id_empresa}`}>
                            <div className='table__custom__emp__cell cell__id fw-bold text-center'>{i}</div>
                            <div className='table__custom__emp__cell cell__name'>{e.nombre}</div>
                            <div className='table__custom__emp__cell'>correo@gmail.com</div>
                        </Link>
                        })}
                    </div>
                    </div>
                ) : (
                    <p>No tiene empresas asignadas.</p>
                )}
                </>
            )}
        </>
    )
}

export default ListaEmpresasPA