import React, { useEffect, useState } from 'react'
import { useLocation, Link, useParams } from "react-router-dom"
import "./Proyectos.scss"
import { Accordion } from 'react-bootstrap';
import { jwtDecode } from "jwt-decode"
import { Oval } from 'react-loader-spinner'
import IllustrationAccess from "../../assets/img/access.png"
import Tareas from '../../components/Tareas';
import ModalNewProyecto from '../../components/Modales/ModalNewProyecto';

import { usePlanes } from '../../context/PlanesContext';

export const newContext = React.createContext()

function Proyectos() {

    const { empresa, fetchEmpresa, area, fetchArea } = usePlanes()

    const { idConsult, id, idArea, year } = useParams()

    const { state } = useLocation()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [loadingTar, setLoadingTar] = useState(false)
    const [errorTar, setErrorTar] = useState(null)
    const [tareasByProyecto, setTareasByProyecto] = useState(null)
    const [idProyecto, setIdProyecto] = useState(null)
    const [yearSelec, setYearSelec] = useState(null)
    const [titleProyecto, setTitleProyecto] = useState(null)
    const [descripcionProyecto, setDescripcionProyecto] = useState(null)
    const [modalProyecto, setModalProyecto] = useState(false)

    const [tareasRealporProyecto, setTareasRealporProyecto] = useState(null)
    const [tareasNorealporProyecto, setTareasNorealporProyecto] = useState(null)

    const [expandedRow, setExpandedRow] = useState(null);

    const [proyectosClose, setProyectosClose] = useState(false)

    const [proyectos, setProyectos] = useState([])

    const [areas, setAreas] = useState([]);

    //subtareas
    const [subtareas, setSubtareas] = useState([])

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const fetchAreas = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER,
                    idEmpresa: id
                })
            })
            const data = await res.json()
            if(data.status !== 0){
                console.log(data.errorDetalle)
            } else {
                setAreas(data.objeto.areas)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const firstFetch = () => {
            fetchProyectos()
            .then(res => {
                if(res.error !== 0){
                    setLoading(false)
                    setError(res.errorDetalle)
                } else {
                    setLoading(false)
                    setProyectos(res.objeto)
                }
            })
        }
        
        fetchAreas()
        firstFetch()
        if (USER.puesto < 3) {
            fetchEmpresa(USER, idConsult, id)
        }
        fetchArea(USER, id, idArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const fetchProyectos = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/planAccion/viewProyect`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    idArea: parseInt(idArea),
                    user: USER
                })
            });
            const data = await res.json()
            return data
        } catch (error) {
            setError(error)
        }
    }

    const fetchTareasById = async(id) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/planAccion/viewTask`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: JSON.stringify({
                user: USER,
                idProyecto: id
              })
            })
            const data = await res.json()
            return data
        } catch (error) {
            console.log(error)
        }
    }

    const handleTareaById = async (id, year, titleProyecto, detalles) => {
        setLoadingTar(true)
        fetchTareasById(id)
        .then(res => {
            if(res.error !== 0){
                setLoadingTar(false)
                setErrorTar(res.errorDetalle)
            } else {
                setLoadingTar(false)
                setTareasByProyecto(res.objeto)
            }
        })
        // Insertar fetch métrica luego
        setIdProyecto(id)
        setYearSelec(year)
        setTitleProyecto(titleProyecto)
        setDescripcionProyecto(detalles)
        setSubtareas([])
        setExpandedRow(null)
    }

    const handleFormProyecto = (e) => {
        e.preventDefault()
        setModalProyecto(true)
    }

    const handleCloseProyectos = () => {
        setProyectosClose(!proyectosClose)
    }

    const ruta = USER.puesto >= 3 ? "" : `/consultora/${idConsult}/empresa/${id}`

    return (
        <newContext.Provider 
            // Falta agregar fetchMetrica
            value={{idArea, USER, areas, subtareas, setSubtareas, loadingTar, setLoadingTar, errorTar, setErrorTar, proyectos, setProyectos, fetchProyectos, fetchTareasById, tareasByProyecto, setTareasByProyecto, yearSelec, setYearSelec, idProyecto, setIdProyecto, titleProyecto, descripcionProyecto, setTitleProyecto, setDescripcionProyecto, expandedRow, setExpandedRow, tareasRealporProyecto, tareasNorealporProyecto, setTareasRealporProyecto, setTareasNorealporProyecto, handleCloseProyectos, proyectosClose}}>
            <ModalNewProyecto show={modalProyecto} onHide={()=>setModalProyecto(false)}/>
            <div className='proyectos section'>
                <div className='section__header d-flex flex-row align-items-end mb-4'>
                    <i className='bi bi-bar-chart-steps me-2'></i>
                    <h4 className='m-0'><Link to={'/planes-de-accion'} className='breadcrumb__generico'>Planes de acción</Link> {USER.puesto < 3 && <><i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/planes-de-accion${ruta}`}>{`${empresa}`}</Link></>} <i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/planes-de-accion${ruta}/area/${idArea}`}>{area}</Link> <i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/planes-de-accion${ruta}/area/${idArea}/${year}`}>{year}</Link> <i className="bi bi-chevron-right"></i> proyectos</h4>
                </div>
                {loading ? (
                    <div className='loading__proyectos d-flex flex-column align-items-center justify-content-center'>
                        <Oval
                            visible={true}
                            height="80"
                            width="80"
                            color="#0d6efd"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        <p className='fw-medium'>Loading...</p>
                    </div>
                ): (
                    <>
                        {error ? (
                            <div className='proyectos__error d-flex flex-column align-items-center justify-content-center'>
                                <img className='mb-4' src={IllustrationAccess} alt="" />
                                <h2>Mensaje de error:</h2>
                                <p>{error}</p>
                            </div>
                        ) : (
                            <>
                                {state === null ? (
                                    <div className='proyectos__error d-flex flex-column align-items-center justify-content-center'>
                                        <img className='mb-4' src={IllustrationAccess} alt="" />
                                        <h2 className='mb-4'>Ha ocurrido un error :(</h2>
                                        <Link className='btn btn-primary shadow-sm fw-medium rounded-pill px-4' to={"/planes-de-accion"}>Regresar a Planes de acción</Link>   
                                    </div>
                                ) : (
                                    <div className='proyectos__main d-flex flex-column flex-md-row'>
                                        <div className={`${proyectosClose ? "invisible proyectos__main__menu--close" : "d-flex"} proyectos__main__menu mb-4 mb-md-0 flex-column align-items-start justify-content-between`} >
                                            <div className='container__accordion'>
                                                <Accordion className='mb-2 mb-md-0' defaultActiveKey="0">
                                                    <Accordion.Item eventKey='0'>
                                                        <Accordion.Header>{state.year}</Accordion.Header>
                                                        <Accordion.Body className='d-flex flex-column align-items-start'>
                                                            {proyectos.map((c,i) => {
                                                                return <button
                                                                    key={i} 
                                                                    className='btn__accordion btn d-flex align-items-center' 
                                                                    onClick={() => handleTareaById(c.id_proyecto, state.year, c.nombre, c.detalles)}
                                                                    >
                                                                        <i className="bi bi-chevron-right me-2 active"></i><span>{c.nombre}</span>
                                                                </button>
                                                            })}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                            <button 
                                                className='proyectos__main__menu__btn btn btn-primary rounded-pill shadow-sm fw-medium'
                                                onClick={handleFormProyecto}
                                                >Agregar proyecto
                                            </button>
                                        </div>
                                        <Tareas />
                                    </div>
                                )}
                            </>
                            
                        )}
                    </>
                )}
            </div>
        </newContext.Provider>
    )
}

export default Proyectos