import React from 'react'
import "../Home/Home.scss"
import { jwtDecode } from "jwt-decode"
import ListaConsultorasPA from '../../components/PlanesAccion/ListaConsultorasPA'
import ListaEmpresasPA from '../../components/PlanesAccion/ListaEmpresasPA'
import ListaAreasPA from '../../components/PlanesAccion/ListaAreasPA'

function HomePlanesAccion() {
    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const title = (
        <>
            <div className='section__header d-flex flex-row align-items-end mb-4'>
                <i className='bi bi-bar-chart-steps me-2'></i>
                <h4 className='m-0'>Planes de acción</h4>
            </div>
        </>
    )

    if(USER.puesto === 1) {
        return (
            <div className='home home__PA section'>
                {title}
                <ListaConsultorasPA />
            </div>
        )
    }

    if(USER.puesto === 2) {
        return (
            <div className='home home__PA section'>
                {title}
                <ListaEmpresasPA />
            </div>
        )
    }

    if(USER.puesto === 3 || USER.puesto === 4 || USER.puesto === 5) {
        return (
            <div className='home home__PA section'>
                {title}
                <ListaAreasPA />
            </div>
        )
    }
}

export default HomePlanesAccion