import React from 'react'
import "./AsistenteIA.scss"

function AsistenteIA() {
  return (
    <div className='IA__container'>
      <iframe
        src="https://app.droxy.ai/guest-agent/6754ff6cc193877f06efbb1e"
        width="100%"
        height="100%"
        frameborder="0">
      </iframe>
    </div>
  )
}

export default AsistenteIA