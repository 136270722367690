import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { Oval } from 'react-loader-spinner'
import { jwtDecode } from "jwt-decode"
import "../Home/Areas.scss"

import { usePlanes } from '../../context/PlanesContext'

function AreasDatIN() {
  const { empresa, fetchEmpresa } = usePlanes()

  const { idConsult, id } = useParams()

  const [loading, setLoading] = useState(true)
  const [areas, setAreas] = useState([])
  const [error, setError] = useState(null)

  const auth = localStorage.getItem("token")
  const jwtParse = jwtDecode(auth)
  const USER = jwtParse.apirest.objeto

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            user: USER,
            idEmpresa: id
          })
        })
        const data = await res.json()
        if(data.status !== 0){
          setError(data.codeError)
          setLoading(false)
        } else {
          setAreas(data.objeto.areas)
          setLoading(false)
        }
      } catch (error) {
        setError(error)
      }
    }

    fetchAreas()
    fetchEmpresa(USER, idConsult, id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='areas section'>
            <div className='section__header d-flex flex-row align-items-end mb-4'>
                <i className='bi bi-bar-chart-fill me-2'></i>
                <h4 className='m-0'><Link className='breadcrumb__generico' to={'/dat-in'}>DatIN</Link> <i className="bi bi-chevron-right"></i> {empresa}</h4>
            </div>
            <div className='areas__container'>
              {loading ? (
              <div className='loading__areas d-flex flex-column align-items-center justify-content-center'>
                <Oval
                    visible={true}
                    height="80"
                    width="80"
                    color="#0d6efd"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                <p className='fw-medium'>Loading...</p>
              </div>
              ) : (
                  <>
                    {error && <p>{error}</p>}
                    {id === null ? (
                      <p>Ocurrió un error, regrese al home.</p>
                    ) : (
                      <div className='areas__list mb-4'>
                      {areas.map((e,i) => {
                          return <Link 
                            to={`/dat-in/consultora/${idConsult}/empresa/${id}/area/${e.id_area}`} 
                            className="btn areas__list__area border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center" 
                            key={i}
                          >
                          <h4 className='p-0 m-0 text-start'>{e.nombre_del_Area}</h4>
                          </Link>
                      })}
                      </div>
                    )}
                  </>
                  
              )}
            </div>
        </div>
  )
}

export default AreasDatIN