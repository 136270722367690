import React, { useState, useEffect } from 'react'
import { jwtDecode } from "jwt-decode"
import { Link } from "react-router-dom"
import { Oval } from 'react-loader-spinner'
import IllustrationAccess from "../../assets/img/access.png"
import "../../pages/NotFound/NotFound.scss"

function ListaConsultorasPA() {

    const [loadingConsultoras, setLoadingConsultoras] = useState(true)
    const [loadingEmpresas, setLoadingEmpresas] = useState(false)

    const [error, setError] = useState(null)
    const [errorEmpresa, setErrorEmpresa] = useState(null)
    const [empty, setEmpty] = useState(null)

    const [consultoras, setConsultoras] = useState([])
    const [empresas, setEmpresas] = useState([])

    const [idConsult, setIdConsult] = useState(null)

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const fetchConsultoras = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/consultorasView`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER
                })
            })
            const data = await res.json()
            if(data.status !== 0){
                setError(data.codeError)
                setLoadingConsultoras(false)
            } else {
                if(data.objeto.length === 0) {
                    setEmpty("No tienes consultoras asignadas.")
                    setLoadingConsultoras(false)
                } else {
                    setConsultoras(data.objeto)
                    setLoadingConsultoras(false)
                }  
            }
        } catch (error) {
            setError(error.toString())
            setLoadingConsultoras(false)
        }
    }

    useEffect(() => {
        fetchConsultoras()
    }, [])

    const handleEmpresasById = async (id) => {
        setIdConsult(id)
        setLoadingEmpresas(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/empresasView`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER,
                    idConsultora: id
                })
            })
            const data = await res.json()
            if(data.status !== 0){
                setErrorEmpresa(data.codeError)
                setLoadingEmpresas(false)
            } else {
                setEmpresas(data.objeto)
                setLoadingEmpresas(false)
            }
        } catch (error) {
            setErrorEmpresa(error.toString())
            setLoadingEmpresas(false)
        }
    }
    
    return (
        <div className='home__dashboards'>
            {loadingConsultoras ? (
                <div className='loading__home d-flex flex-column align-items-center justify-content-center'>
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#0d6efd"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    <p className='fw-medium'>Loading...</p>
                </div>
            ) : (
                <>
                    {error ? (
                        <div className='not__found d-flex flex-column align-items-center justify-content-center'>
                            <img className='mb-4' src={IllustrationAccess} alt="" />
                            <h2 className='fw-semibold mb-2'>Not Found 404</h2>
                            <p className='text-center'>{error}</p>
                            <Link className='btn shadow-sm fw-medium' to={"/home"}>Regresar al home</Link>
                        </div>
                    ) : (
                    <>
                        {consultoras.length > 0 ? (
                            <div className='home__consultoras mb-4'>
                                {consultoras.map((e,i) => {
                                    return <div key={i} onClick={()=> handleEmpresasById(e.id_consultora)} className='home__consultoras__consultora border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center'>
                                        <h4 className='p-0 m-0 text-start'>{e.nombre}</h4>
                                    </div>
                                })}
                            </div>
                        ) : <div className='not__found d-flex flex-column align-items-center justify-content-center'>
                                <img className='mb-4' src={IllustrationAccess} alt="" />
                                <h2 className='fw-semibold mb-2'>Oops!</h2>
                                <p className='text-center'>{empty}</p>
                                <Link className='btn shadow-sm fw-medium' to={"/home"}>Regresar al home</Link>
                            </div>
                        }
                    </>
                    )}
                </>
            )}
            {/* LISTADO DE EMPRESAS */}
            {loadingEmpresas ? (
                <div className='loading__home d-flex flex-column align-items-center justify-content-center'>
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#0d6efd"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    <p className='fw-medium'>Loading...</p>
                </div>
            ) : (
                <>
                    {errorEmpresa ? (
                        <div className='not__found d-flex flex-column align-items-center justify-content-center'>
                            <img className='mb-4' src={IllustrationAccess} alt="" />
                            <h2 className='fw-semibold mb-2'>Not Found 404</h2>
                            <p className='text-center'>{errorEmpresa}</p>
                            <Link className='btn shadow-sm fw-medium' to={"/home"}>Regresar al home</Link>
                        </div>
                    ) : (
                    <>
                        {empresas.length > 0 ? (
                            <div className='table__custom'>
                            <div className='table__custom__header'>
                                <div className='table__custom__cell table__custom__cell--title fw-bold cell__id text-center'></div>
                                <div className='table__custom__cell table__custom__cell--title fw-bold cell__name'>Nombre</div>
                                <div className='table__custom__cell table__custom__cell--title fw-bold'>Responsable</div>
                            </div>
                            <div className='table__custom__body'>
                                {empresas.map((e, i) => {
                                return <Link 
                                    key={i} 
                                    className='table__custom__row text-decoration-none text-dark' 
                                    to={`/planes-de-accion/consultora/${idConsult}/empresa/${e.id_empresa}`} 
                                >
                                    <div className='table__custom__cell cell__id fw-bold text-center'>{i}</div>
                                    <div className='table__custom__cell cell__name'>{e.nombre}</div>
                                    <div className='table__custom__cell'>correo@gmail.com</div>
                                </Link>
                                })}
                            </div>
                            </div>
                        ) : (
                            <div className='invisible'></div>
                        )}
                    </>
                    )}
                </>
            )}
        </div>
    )
}

export default ListaConsultorasPA