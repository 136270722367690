import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from '../pages/Login/Login'
import Home from '../pages/Home/Home'
import Okr from '../pages/Okr/Okr'
import AsistenteIA from '../pages/AsistenteIA/AsistenteIA'

import ProtectedRoutes from '../Services/ProtectedRoutes'
import Layout from '../components/Layout/Layout'
import PowerBI from '../components/PowerBI'
import NotFound from '../pages/NotFound/NotFound'
import ResetPassword from '../pages/ResetPassword/ResetPassword'

import HomePlanesAccion from '../pages/PlanesAccion/HomePlanesAccion'
import Year from "../pages/PlanesAccion/Year"
import Proyectos from '../pages/PlanesAccion/Proyectos'
import Perfil from '../pages/Perfil/Perfil'
import Areas from '../pages/Home/Areas'
import AreasPA from '../pages/PlanesAccion/AreasPA'
import ProyectByYear from "../pages/PlanesAccion/ProyectByYear"

import HomeDatIN from '../pages/DatIN/HomeDatIN'
import AreasDatIN from '../pages/DatIN/AreasDatIN'
import IndicadoresByYear from '../pages/DatIN/IndicadoresByYear'
import DatIN from '../pages/DatIN/DatIN'

import { PlanesProvider } from '../context/PlanesContext'

function MyRoutes() {
  return (
    <BrowserRouter>
      <PlanesProvider>
        <Routes>
            <Route path="/" element={<Login />}/>
            <Route path="reset-password" element={<ResetPassword />} />
            {/* protected routes */}
            <Route element={<ProtectedRoutes />}>
              <Route element={<Layout />}>
                  <Route path="home">
                    <Route index element={<Home />} />
                    {/* USUARIO 1 y 2 */}
                    <Route path='empresa/:id'>
                      <Route index element={<Areas />}/>
                      <Route path="powerbi/:idArea" element={<PowerBI />} />
                    </Route>
                    {/* USUARIO 3 y demás */}
                    <Route path='powerbi/:idArea'>
                      <Route index element={<PowerBI />} />
                    </Route>
                  </Route>
                  <Route path="/perfil" element={<Perfil />} />
                  {/* PLANES DE ACCION */}
                  <Route path="planes-de-accion">
                    <Route index element={<HomePlanesAccion />} />
                    {/* USUARIO 1 y 2 */}
                    <Route path='consultora/:idConsult/empresa/:id'>
                      <Route index element={<AreasPA />}/>
                      <Route path="area/:idArea">
                        <Route index element={<ProyectByYear />} />
                        <Route path=':year'>
                          <Route index element={<Year />} />
                          <Route path='proyectos' element={<Proyectos />} />
                        </Route>
                      </Route>
                    </Route>
                    {/* USUARIO 3 y demás */}
                    <Route path='area/:idArea'>
                      <Route index element={<ProyectByYear />} />
                      <Route path=':year'>
                        <Route index element={<Year />} />
                        <Route path='proyectos' element={<Proyectos />} />
                      </Route>
                    </Route>
                  </Route>
                  {/* FIN DE PLANES DE ACCION */}
                  <Route path="/okr" element={<Okr />}/>
                  <Route path="/asistente-ia" element={<AsistenteIA />}/>
                  <Route path="/dat-in">
                    <Route index element={<HomeDatIN />} />
                    {/* USUARIO 1 y 2 */}
                    <Route path='consultora/:idConsult/empresa/:id'>
                      <Route index element={<AreasDatIN />}/>
                      <Route path="area/:idArea">
                        <Route index element={<IndicadoresByYear />} />
                        <Route path=':year'>
                          <Route index element={<DatIN />} />
                        </Route>
                      </Route>
                    </Route>
                    {/* USUARIO 3 y demás */}
                    <Route path='area/:idArea'>
                      <Route index element={<IndicadoresByYear />} />
                      <Route path=':year'>
                        <Route index element={<DatIN />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
        </Routes>
      </PlanesProvider>
    </BrowserRouter>
  )
}

export default MyRoutes